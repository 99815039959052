@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.page-header-tools {
  align-self: center;
  padding-left: 5px;
}
.v-list {
  background-color: var(--color-flat-panel-theme);
}
