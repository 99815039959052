@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-panel {
  &.progression-downtime-panel {
    margin-top: var(--grid-gutter);
  }

  .panel-header {
    display: flex;

    .chart-period-options {
      padding-top: var(--grid-gutter);

      ::v-deep &.v-text-field {
        .v-text-field__details {
          display: none !important;
        }
      }
    }
    .panel-options {
      display: flex;

      .v-btn-toggle {
        margin-left: auto;
      }
    }
  }
}
.data-report {
  margin-bottom: var(--grid-gutter);
  // dl>dt
  .label {
    line-height: 1.3;
  }
  // dl>dd
  .data {
    padding-top: 0.5rem;
  }
}
.data-reports-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 1.5rem;

  // Definition List (dl)
  .data-report {
    margin-bottom: 0;
    padding: 0.3rem var(--grid-gutter) 0.3rem;
    border-left: 1px solid var(--color-border-theme);

    &:first-child {
      padding-left: 0;
      border-left: none;
    }
    .data {
      padding-top: 0.75rem;
    }
  }
}
.downtime-percentage-bar {
  background-color: var(--wxData_bgColor);
  height: 100%;
  border-radius: 5px;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: hsl(219, 20%, 30%);
}
