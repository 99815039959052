@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

/**
 * Form filters
 */
.selector {
  min-width: 9rem;
  max-width: 16rem;
}

.filter-form {
  fieldset.filters {
    display: flex;
    flex-basis: content;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    border: 0 solid var(--color-border-theme);

    // style
    ::v-deep .v-input.v-input--dense {
      .v-input__control {
        .v-input__slot {
          border-radius: var(--border-radius-form-elements);

          // hide border-bottom
          &::before,
          &::after {
            display: none;
            border-color: transparent !important;
          }
        }
      }
    }
    .active-filter-counter {
      white-space: nowrap;
      font-weight: 700; // bold
      .string {
        padding-left: 0.2rem;
        font-size: var(--font-size-sm);
        font-weight: 400;
        color: var(--color-text-subtle-theme);
      }
    }
    // spacing
    ::v-deep .v-input:not(:first-child) {
      margin-left: var(--grid-gutter);
    }
  }
}
.dateRangePicker {
  margin-left: var(--grid-gutter);
  margin-right: var(--grid-gutter);
}

.chart-data-view {
  .chart-container {
    position: relative;
    min-width: 1rem;
    min-height: 1rem;
    border-radius: var(--border-radius-sm);
    // hard coded colors for both Light & Dark Modes
    color: rgba(Black, 0.87); // light var(--color-text-theme)
    background-color: rgba(White, 0);
  }
  .aside-chart-container {
    padding-top: 1rem;
  }
  .switch-graph-line-type {
    margin: 0 0 calc(var(--grid-gutter) * 1.5);
    padding-bottom: 0;
    border-bottom: 1px solid var(--color-border-theme);
  }
}

// Definition List (dl)
.data-report {
  margin-bottom: var(--grid-gutter);
  // dl>dt
  .label {
    line-height: 1.3;
  }
  // dl>dd
  .data {
    padding-top: 0.5rem;
  }
}
.data-reports-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 1.5rem;

  // Definition List (dl)
  .data-report {
    margin-bottom: 0;
    padding: 0.3rem var(--grid-gutter) 0.3rem;
    border-left: 1px solid var(--color-border-theme);

    &:first-child {
      padding-left: 0;
      border-left: none;
    }
    .data {
      padding-top: 0.75rem;
    }
  }
}
/**
 * WxChartLineGraph.vue
 */
.wx-panel {
  &.progression-downtime-panel {
    margin-top: var(--grid-gutter);
  }

  .panel-header {
    display: flex;

    .chart-period-options {
      padding-top: var(--grid-gutter);

      ::v-deep &.v-text-field {
        .v-text-field__details {
          display: none !important;
        }
      }
    }
    .panel-options {
      display: flex;

      .v-btn-toggle {
        margin-left: auto;
      }
    }
  }
}

// Potential yearly savings text
.savings-title {
  padding-top: var(--grid-gutter);
}
.primary-title {
  margin-bottom: 4px;
  font-weight: normal;
  line-height: 1;
}

.wx-typo-h3 {
  font-size: var(--font-size-h3);
  line-height: 0.5;
  font-weight: 700; /* Bold */
}

.primary-value {
  margin-bottom: 10px;
  line-height: 1.2;
}

.wx-typo-h1 {
  font-size: var(--font-size-h1);
  line-height: 1.16666666;
  font-weight: 700; /* bold */
}

.downtime-percentage-bar {
  background-color: var(--wxData_bgColor);
  height: 100%;
  border-radius: 5px;
}
.no-planned-reason {
  font-size: var(--font-size-xs);
  color: var(--color-text-theme);
}
