@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.chart-data-view {
  .chart-container {
    position: relative;
    min-width: 1rem;
    min-height: 1rem;
    border-radius: var(--border-radius-sm);
    // hard coded colors for both Light & Dark Modes
    color: rgba(Black, 0.87); // light var(--color-text-theme)
    background-color: rgba(White, 0);
  }
  .aside-chart-container {
    padding-top: 1rem;
  }
  .switch-graph-line-type {
    margin: 0 0 calc(var(--grid-gutter) * 1.5);
    padding-bottom: 0;
    border-bottom: 1px solid var(--color-border-theme);
  }
}
.data-report {
  margin-bottom: calc(var(--grid-gutter) / 2);
  // dl>dt
  .label {
    line-height: 1.1;
  }
  // dl>dd
  .data {
    padding-top: 0.3rem;
  }
}
.data-reports-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;

  // Definition List (dl)
  .data-report {
    margin-bottom: 0;
    padding: 0.25rem var(--grid-gutter) 0.25rem;
    border-left: 1px solid var(--color-border-theme);

    &:first-child {
      padding-left: 0;
      border-left: none;
    }
    .data {
      padding-top: 0.5rem;
    }
  }
}
