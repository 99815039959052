@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-contextualized-help {
  &__activator {
    opacity: 0.6;
  }
}
.menu-title {
  font-weight: bold;
  font-size: 1.1em;
}
.v-menu__content {
  border-radius: var(--border-radius-lg);
}
.v-card {
  background-color: var(--color-base-background);
}
