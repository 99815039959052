@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-date-range-picker {
  &.filter-form {
    width: 100%;
    min-width: 260px;
    max-width: 300px;
  }

  // .wx-period-picker__menu (dropdown)
  &__menu {
    border-radius: var(--border-radius-form-elements);
  }

  // .wx-period-picker__window (modal background)
  &__window {
    // over-rule vuetify
    ::v-deep .v-card.v-picker,
    ::v-deep .v-list,
    ::v-deep .v-picker__body {
      background-color: transparent !important;
    }
  }

  // .wx-period-picker__card.v-card
  &__card {
    width: 100%;
    min-width: 320px;
    overflow-y: hidden;

    .close-window-btn {
      position: absolute;
      z-index: 1;
      top: calc(var(--dialog-close-offset) / 3);
      right: calc(var(--dialog-close-offset) / 3);
    }

    // List of options
    .first-column {
      background-color: var(--color-element-layer1);
      border-top-left-radius: var(--border-radius-sm);
      border-bottom-left-radius: var(--border-radius-sm);
      padding-top: 0px;
      padding-bottom: 0px;

      .period-list-item {
        min-height: 2rem;
        font-weight: 400; /* Regular */
      }

      @media ($wx-xs-max) {
        max-width: 300px;
        margin-inline: auto;
      }
      @media ($wx-sm-min) {
        padding-inline: 0 !important;
        border-right: 1px solid var(--color-border-theme);
      }
      @media ($wx-md-min) {
        .period-list-item {
          min-height: 3rem;
        }
      }
    }

    // Calendar container
    .second-column {
      border-radius: 0 !important;

      .v-picker.v-card {
        // disable click event
        &.preset-period-item-selected {
          pointer-events: none;
        }
      }
    }

    .data-inline-grid {
      max-width: 18rem;

      dt {
        text-align: right;
        color: var(--color-text-subtle-theme);
      }
    }
    .timezone-info {
      color: var(--color-text-subtle-theme);
    }
  }
}

.d-flex > .flex-mobile-phone-row {
  align-self: flex-end;
}

/**
 * Duplicated from ui/FormFooterActions.vue
 */
.form-footer-actions {
  border-color: transparent;
  border-radius: 0;

  .v-btn {
    &.submit-btn {
      order: 1;
    }
    &.reset-btn {
      order: 2;
    }
  }
}
