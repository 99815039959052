@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-autocomplete {
  &__content {
    &.v-menu__content {
      background-color: var(--color-element-layer2);
    }

    .v-list {
      &.v-select-list {
        background-color: transparent;
      }
    }
  }

  /**
  * Filter style = No border-bottom
  * prop `hide-details` OR `class="filter-style"`
  */
  &.filter-style,
  &.v-input--hide-details {
    .v-input__control {
      .v-input__slot {
        border-radius: var(--border-radius-form-elements);
      }
    }
  }
  &.filter-style .v-input__control {
    .v-input__slot {
      // hide border-bottom
      &::before,
      &::after {
        display: none;
        border-color: transparent !important;
      }
    }
  }

  // Displayed as headings
  &.font-size-h2 {
    .v-input__control {
      input {
        font-size: var(--font-size-h2);
        line-height: 1.4rem;
      }
    }
  }

  // Height match `v-text-field` width label (41px)
  &.height-not-dense {
    .v-input__control {
      .v-input {
        &__slot {
          min-height: 2.55rem; //
        }
        &__append-inner {
          margin-top: 8px; // vuetify = 3px
          cursor: pointer;
        }
      }
    }
  }
}
