@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-select {
  &.v-text-field {
    input {
      cursor: pointer; // fix Ux
    }
  }

  /**
  * No border-bottom
  */
  &.dropdown-nav,
  &.filter-style {
    .v-input__control {
      .v-input__slot {
        border-radius: var(--border-radius-form-elements);

        // hide border-bottom
        &::before,
        &::after {
          display: none;
          border-color: transparent !important;
        }
      }
    }
  }

  &.dropdown-nav {
    .v-input__slot {
      .v-label {
        color: var(--color-text-theme);
        font-weight: 700;
      }
    }
  }
  // Dropdown used as headings (ShiftSchedule.vue)
  &.h2 {
    .v-input__slot {
      .v-label {
        top: 9px;
        font-size: var(--font-size-h2);
        height: 1.7rem;
        line-height: 1.4rem;
      }
    }
  }
}
