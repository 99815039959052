@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// local var
$chartOption_borderWidth: 3px;
$chartOption_borderDash_width: 2px;
$legends_label_lineHeight: 18px;
$legends_swatch_width: 16px; // based on font-size applied on v-icon

// style
.wx-graph-legend {
  margin-top: var(--wx-panel-container-padding);
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  // .wx-graph-legend !isMobile
  @media ($wx-md-min) {
    &__list {
      flex-wrap: nowrap;
      margin-left: 0;
    }
    &__item {
      align-items: flex-start;
      width: 20%;
    }
  }

  // a.legend-anchor
  .legend-anchor {
    display: flex;
    position: relative;
    color: var(--color-text-subtle-theme);
    cursor: pointer;

    // .legend-anchor__swatch
    &__swatch {
      display: block;
      width: $chartOption_borderWidth;
      height: $chartOption_borderWidth;
      margin-right: ($legends_swatch_width / 2);
      background: transparent repeat left top;
      border-radius: $chartOption_borderWidth;
      border-top: $chartOption_borderWidth solid transparent;

      .spacer {
        display: block;
        width: $legends_swatch_width;
      }

      &.clicked-hidden {
        margin-top: -6px !important;
        .v-icon {
          color: var(--color-text-theme);
        }
      }
    }
    // .legend-anchor__text
    &__text {
      display: block;
      margin-bottom: 0;
      line-height: 1.23;

      .label,
      .value {
        //overflow: hidden; // hack required by `.text-truncate`
        display: block;
        width: auto;
        //margin-left: 0.5em;
        font-size: var(--font-size-norm);
      }

      @media ($wx-md-min) {
        .value {
          font-size: var(--font-size-h2);
          font-weight: normal;
        }
      }
    }

    // this.chartType = line
    &.line-type-legend {
      .legend-anchor {
        // .legend-anchor__swatch
        &__swatch {
          width: auto;
          margin-top: ($legends_label_lineHeight / 2);
          border-radius: 0;

          &.border-dashed {
            border-top-width: $chartOption_borderDash_width;
            border-top-style: dashed;
          }
        }

        // !isMobile
        @media ($wx-md-min) {
          &__swatch {
            margin-top: (($legends_label_lineHeight / 2) - ($chartOption_borderWidth / 2));
          }
          &__text {
            .label {
              line-height: $legends_label_lineHeight;
            }
          }
        }
        @media ($wx-xl-min) {
          &__swatch {
            margin-top: (($legends_label_lineHeight) - 8px);
          }
          &__text {
            .label {
              line-height: ($legends_label_lineHeight + 7px);
            }
          }
        }
      }
    }
    // this.chartType = bar
    &.bar-type-legend {
      .legend-anchor {
        // .legend-anchor__swatch
        &__swatch {
          width: 15px;
          height: 15px;
          margin-top: 1px;

          &:not(.clicked-hidden) {
            border: 1px solid var(--color-border-theme);
            border-radius: 50%;

            @media ($wx-xl-min) {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
}

.legend-pagination {
  padding-top: var(--wx-panel-container-padding);
  padding-bottom: var(--wx-panel-container-padding);
  border-bottom: 1px solid var(--color-border-theme);

  // !isMobile
  @media ($wx-md-min) {
    border-bottom: none;

    ::v-deep .v-pagination {
      justify-content: flex-end;
    }
  }
}

.positive-trend {
  color: var(--color-primary);
}
.negative-trend {
  color: var(--color-error);
}
